/**
 * Taken from: https://github.com/mattdiamond/Recorderjs/blob/64682b37001a9f14a061485323fbed4110ba82e1/src/recorder.js
 * And transliterated into typescript
 */

const InlineWorker: any = require("inline-worker");

export class Recorder {
  config: Config = {
    bufferLen: 4096,
    numChannels: 2,
    mimeType: "audio/wav",
  };

  recording = false;

  callbacks: {
    getBuffer: GetBufferCallback[];
    exportWAV: ExportWAVCallback[];
  } = {
    getBuffer: [],
    exportWAV: [],
  };

  context: BaseAudioContext;
  node: ScriptProcessorNode;
  worker: Worker;

  constructor(source: AudioNode, cfg: Partial<Config> = {}) {
    Object.assign(this.config, cfg);
    this.context = source.context;
    this.node = this.context.createScriptProcessor.call(
      this.context,
      this.config.bufferLen,
      this.config.numChannels,
      this.config.numChannels,
    );

    this.node.onaudioprocess = e => {
      if (!this.recording) return;

      const buffer: Float32Array[] = [];
      for (var channel = 0; channel < this.config.numChannels; channel++) {
        buffer.push(e.inputBuffer.getChannelData(channel));
      }
      this.worker.postMessage({
        command: "record",
        buffer: buffer,
      });
    };

    source.connect(this.node);
    this.node.connect(this.context.destination); //this should not be necessary

    let self = {};
    this.worker = new InlineWorker(function() {
      let recLength = 0;
      let recBuffers: Float32Array[][] = [];
      let sampleRate: number;
      let numChannels: number;

      // @ts-ignore: confusing this
      this.onmessage = function(e: any) {
        switch (e.data.command) {
          case "init":
            init(e.data.config);
            break;
          case "record":
            record(e.data.buffer);
            break;
          case "exportWAV":
            exportWAV(e.data.type);
            break;
          case "getBuffer":
            getBuffer();
            break;
          case "clear":
            clear();
            break;
          default:
            console.log("Unknown command!", e);
            break;
        }
      };

      function init(config: WorkerConfig) {
        sampleRate = config.sampleRate;
        numChannels = config.numChannels;
        initBuffers();
      }

      function record(inputBuffer: Float32Array[]) {
        for (var channel = 0; channel < numChannels; channel++) {
          recBuffers[channel].push(inputBuffer[channel]);
        }
        recLength += inputBuffer[0].length;
      }

      function exportWAV(type: string) {
        let buffers: Float32Array[] = [];
        for (let channel = 0; channel < numChannels; channel++) {
          buffers.push(mergeBuffers(recBuffers[channel], recLength));
        }
        let interleaved;
        if (numChannels === 2) {
          interleaved = interleave(buffers[0], buffers[1]);
        } else {
          interleaved = buffers[0];
        }
        let dataview = encodeWAV(interleaved);
        let audioBlob = new Blob([dataview], { type: type });

        // @ts-ignore: confusing this
        this.postMessage({ command: "exportWAV", data: audioBlob });
      }

      function getBuffer() {
        let buffers: Float32Array[] = [];
        for (let channel = 0; channel < numChannels; channel++) {
          buffers.push(mergeBuffers(recBuffers[channel], recLength));
        }

        // @ts-ignore: confusing this
        this.postMessage({ command: "getBuffer", data: buffers });
      }

      function clear() {
        recLength = 0;
        recBuffers = [];
        initBuffers();
      }

      function initBuffers() {
        for (let channel = 0; channel < numChannels; channel++) {
          recBuffers[channel] = [];
        }
      }

      function mergeBuffers(recBuffers: Float32Array[], recLength: number) {
        let result = new Float32Array(recLength);
        let offset = 0;
        for (let i = 0; i < recBuffers.length; i++) {
          result.set(recBuffers[i], offset);
          offset += recBuffers[i].length;
        }
        return result;
      }

      function interleave(inputL: Float32Array, inputR: Float32Array) {
        let length = inputL.length + inputR.length;
        let result = new Float32Array(length);

        let index = 0,
          inputIndex = 0;

        while (index < length) {
          result[index++] = inputL[inputIndex];
          result[index++] = inputR[inputIndex];
          inputIndex++;
        }
        return result;
      }

      function floatTo16BitPCM(
        output: DataView,
        offset: number,
        input: Float32Array,
      ) {
        for (let i = 0; i < input.length; i++, offset += 2) {
          let s = Math.max(-1, Math.min(1, input[i]));
          output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
        }
      }

      function writeString(view: DataView, offset: number, string: string) {
        for (let i = 0; i < string.length; i++) {
          view.setUint8(offset + i, string.charCodeAt(i));
        }
      }

      function encodeWAV(samples: Float32Array) {
        let buffer = new ArrayBuffer(44 + samples.length * 2);
        let view = new DataView(buffer);

        /* RIFF identifier */
        writeString(view, 0, "RIFF");
        /* RIFF chunk length */
        view.setUint32(4, 36 + samples.length * 2, true);
        /* RIFF type */
        writeString(view, 8, "WAVE");
        /* format chunk identifier */
        writeString(view, 12, "fmt ");
        /* format chunk length */
        view.setUint32(16, 16, true);
        /* sample format (raw) */
        view.setUint16(20, 1, true);
        /* channel count */
        view.setUint16(22, numChannels, true);
        /* sample rate */
        view.setUint32(24, sampleRate, true);
        /* byte rate (sample rate * block align) */
        view.setUint32(28, sampleRate * 4, true);
        /* block align (channel count * bytes per sample) */
        view.setUint16(32, numChannels * 2, true);
        /* bits per sample */
        view.setUint16(34, 16, true);
        /* data chunk identifier */
        writeString(view, 36, "data");
        /* data chunk length */
        view.setUint32(40, samples.length * 2, true);

        floatTo16BitPCM(view, 44, samples);

        return view;
      }
    }, self);

    this.worker.postMessage({
      command: "init",
      config: {
        sampleRate: this.context.sampleRate,
        numChannels: this.config.numChannels,
      },
    });

    this.worker.onmessage = e => {
      const data = e.data as MessageFromWorker;
      let cb = this.callbacks[data.command].pop();
      if (typeof cb == "function") {
        cb(data.data as any);
      }
    };
  }

  record() {
    this.recording = true;
  }

  stop() {
    this.recording = false;
  }

  clear() {
    this.worker.postMessage({ command: "clear" });
  }

  getBuffer(cb: GetBufferCallback) {
    if (!cb) throw new Error("Callback not set");

    this.callbacks.getBuffer.push(cb);

    this.worker.postMessage({ command: "getBuffer" });
  }

  exportWAV(cb: ExportWAVCallback, mimeType?: string) {
    mimeType = mimeType || this.config.mimeType;
    if (!cb) throw new Error("Callback not set");

    this.callbacks.exportWAV.push(cb);

    this.worker.postMessage({
      command: "exportWAV",
      type: mimeType,
    });
  }
}

export type GetBufferCallback = (rv: Float32Array[]) => void;
export type ExportWAVCallback = (rv: Blob) => void;
export interface Config {
  bufferLen: number;
  numChannels: number;
  mimeType: string;
}

interface GetBufferMessageFromWorker {
  command: "getBuffer";
  data: Float32Array[];
}

interface ExportWAVMessageFromWorker {
  command: "exportWAV";
  data: Blob;
}

type MessageFromWorker =
  | GetBufferMessageFromWorker
  | ExportWAVMessageFromWorker;

interface WorkerConfig {
  sampleRate: number;
  numChannels: number;
}

export default Recorder;
