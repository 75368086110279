import React from 'react';

export default function Footer() {
  return <footer className="container" style={{flex: '0 1 50px'}}>
    <div className="row">
      <div className="col">
        <span className='text-muted'>
          &copy; 2019 <a href="https://twitter.com/yuzeh">@yuzeh</a> &middot; <a href="https://www.yuzeh.com/">home</a>
        </span>
      </div>
    </div>
  </footer>;
};