import { Note } from './utilities/music';

const Comlink = require('comlink');

export interface TranscriptionResult {
  notes: Note[];
};

export interface NTIfaceType {
  transcribe(buffer: Float32Array[], options: {[x: string]: any}): Promise<TranscriptionResult>;
  ready(): Promise<void>;
};

const underlyingWorker = new Worker('/js/note-transcription-worker.js');
export default Comlink.wrap(underlyingWorker) as NTIfaceType;