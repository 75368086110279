import React from 'react';

// @ts-ignore
import * as NodeWAV from 'node-wav';

import '../../base.scss';

import * as Audio from '../../utilities/audio';
import * as WaveFormAnnotator from '../../components/WaveFormAnnotator';
import Footer from '../../components/Footer';

interface State {
  wavData: Audio.AudioData | string;
};

export default class Adhoc extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {wavData: ''};
  }

  async componentWillMount() {
    const result = await fetch('/samples/04.wav');
    const data = await result.arrayBuffer();
    this.setState({wavData: NodeWAV.decode(data) as Audio.AudioData});
    //this.setState({wavData: '/samples/04.wav'});
  }

  renderContent() {
    if (this.state.wavData) {
      return <WaveFormAnnotator.Component rawData={this.state.wavData} notes={data} />;
    }
    return null;
  }

  render() {
    return <React.Fragment>
      <div className="container" style={{flex: '0 1 auto'}}>
        <div className="row">
          <div className="col">
            <h1 className="text-center mt-3">Ad-hoc testing</h1>
            <h3 className="text-center"><small className="text-muted">woo</small></h3>
          </div>
        </div>
      </div>
      <article className="container d-flex flex-column" style={{flex: '1 1 auto'}}>
        {this.renderContent()}
      </article>
      <Footer />
    </React.Fragment>;
  }
};

const data = [
  {
    "start": 0.23800456523895264,
    "duration": 0.6037188172340393,
    "f0": 246.94000244140625,
    "power": 0.34623464941978455
  },
  {
    "start": 0.8765532970428467,
    "duration": 0.638548731803894,
    "f0": 212.5045623779297,
    "power": 0.28009894490242004
  },
  {
    "start": 1.5499320030212402,
    "duration": 0.6211338043212891,
    "f0": 187.14552307128906,
    "power": 0.18245910108089447
  },
  {
    "start": 2.217505693435669,
    "duration": 0.09868481010198593,
    "f0": 132.33187866210938,
    "power": 0.12306776642799377
  },
  {
    "start": 2.321995496749878,
    "duration": 0.5572789311408997,
    "f0": 157.37001037597656,
    "power": 0.18731015920639038
  },
  {
    "start": 2.902494430541992,
    "duration": 0.6153287887573242,
    "f0": 141.82980346679688,
    "power": 0.18283197283744812
  },
  {
    "start": 3.5874831676483154,
    "duration": 0.6675736904144287,
    "f0": 123.47000122070312,
    "power": 0.17876502871513367
  },
  {
    "start": 4.3073015213012695,
    "duration": 0.6849886775016785,
    "f0": 102.63294982910156,
    "power": 0.21994513273239136
  },
  {
    "start": 6.019773006439209,
    "duration": 0.22639456391334534,
    "f0": 101.4541015625,
    "power": 0.20964092016220093
  },
  {
    "start": 6.257777690887451,
    "duration": 0.2438095211982727,
    "f0": 120.64994049072266,
    "power": 0.2478945255279541
  },
  {
    "start": 6.536417007446289,
    "duration": 0.2438095211982727,
    "f0": 140.2007293701172,
    "power": 0.28383636474609375
  },
  {
    "start": 6.838276386260986,
    "duration": 0.24961450695991516,
    "f0": 153.77566528320312,
    "power": 0.271164208650589
  },
  {
    "start": 7.128525733947754,
    "duration": 0.23800453543663025,
    "f0": 189.32005310058594,
    "power": 0.24227772653102875
  },
  {
    "start": 7.401360511779785,
    "duration": 0.22639456391334534,
    "f0": 164.8126678466797,
    "power": 0.22528252005577087
  },
  {
    "start": 7.679999828338623,
    "duration": 0.2205895632505417,
    "f0": 138.59039306640625,
    "power": 0.16601815819740295
  },
  {
    "start": 7.918004035949707,
    "duration": 0.21478457748889923,
    "f0": 124.90465545654297,
    "power": 0.13715825974941254
  },
  {
    "start": 8.24888801574707,
    "duration": 0.3076643943786621,
    "f0": 105.03187561035156,
    "power": 0.1780727356672287
  }
];