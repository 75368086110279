import { context } from './sound-effects'; 

export interface AudioData {
  sampleRate: number;
  channelData: Float32Array[];
};

export function createAudioBuffer(data: AudioData) {
  const rv = context().createBuffer(
    data.channelData.length,
    data.channelData[0].length,
    data.sampleRate,
  );

  for (let i = 0; i < data.channelData.length; ++i) {
    rv.copyToChannel(data.channelData[i], i);
  }

  return rv;
};