import * as Tonal from 'tonal';
import {MidiData, MidiTrackData} from 'midi-file';

export interface Note {
  start: number;
  duration: number;
  f0: number;
  power: number;
}

export function quantizePitch(f0: number): number {
  return Tonal.Note.midiToFreq(Math.round(Tonal.Note.freqToMidi(f0)))!;
}

export function f0ToNote(f0: number): string {
  return Tonal.Note.fromMidi(Tonal.Note.freqToMidi(f0)!)!;
}

export function notesToMidi(items: Note[]): MidiData {
  const track: MidiTrackData = [
    { absoluteTime: 0, deltaTime: 0, meta: true, type: 'trackName', text: '' },
    { absoluteTime: 0, deltaTime: 0, channel: 0, type: 'programChange', programNumber: 0 },
  ];
  const midi: MidiData = {
    header: { format: 1, numTracks: 2, ticksPerBeat: 480 },
    tracks: [
      [
        { absoluteTime: 0, deltaTime: 0, meta: true, type: 'trackName', text: '' },
        { absoluteTime: 0, deltaTime: 0, meta: true, type: 'endOfTrack' },
      ],
      track,
    ],
  };

  for (const {start, duration, f0, power} of items) {
    const midiNumber = Tonal.Note.freqToMidi(f0);
    const startTicks = Math.round(start * midi.header.ticksPerBeat * 2);
    const durationTicks = Math.round(duration * midi.header.ticksPerBeat * 2);

    track.push({
      type: 'noteOn',
      absoluteTime: startTicks,
      deltaTime: 0,
      channel: 0,
      noteNumber: Math.floor(midiNumber),
      velocity: 128 * (0.5 + (0.5 + power) / 2.),
    });
    track.push({
      type: 'pitchBend',
      absoluteTime: startTicks,
      deltaTime: 0,
      channel: 0,
      value: Math.round(Math.pow(2, 13) * (midiNumber - Math.floor(midiNumber)) / 2.),
    });

    track.push({
      type: 'noteOff',
      deltaTime: 0,
      absoluteTime: startTicks + durationTicks,
      channel: 0,
      noteNumber: Math.floor(midiNumber),
      velocity: 0,
    });
  }
  track.sort((a, b) => a.absoluteTime - b.absoluteTime);
  let lastTime = 0;
  for (const note of track) {
    note.deltaTime = note.absoluteTime - lastTime;
    lastTime = note.absoluteTime;
    delete note.absoluteTime;
  }
  track.push({
    absoluteTime: track[track.length - 1].absoluteTime,
    deltaTime: 0,
    meta: true,
    type: 'endOfTrack',
  });

  return midi;
}

export function freq2cents(freq: number, base: number = 61.75) {
  // 61.75 corresponds to a B1
  return Math.log2(freq / base) * 1200;
}

export function cents2freq(cents: number, base: number = 61.75) {
  return base * Math.pow(2, cents / 1200)
}

export interface Notes2AbcOptions {
  start?: number;
  bpm?: number;
  baseSubdivision?: number;
  timeSignature?: TimeSignature;
};

export interface TimeSignature {
  numerator: number;
  denominator: 2 | 4 | 8;
}
const fourFour: TimeSignature = {numerator: 4, denominator: 4};

export function notes2abc(notes: Note[], options: Notes2AbcOptions = {}) {
  const {
    start = 0,
    bpm = 120,
    timeSignature = fourFour,
    baseSubdivision = 16,
  } = options;

  const buffer: {note: string, beats: number}[] = [];
  let now = start;
  for (const note of notes) {
    // compute duration of rest
    const restSecs = note.start - now;
    const restBeats = restSecs * bpm / 60 / timeSignature.denominator;
    const roundedRestBeats = Math.round(restBeats * baseSubdivision) / baseSubdivision;
    const roundedRestSeconds = roundedRestBeats * 60 * timeSignature.denominator / bpm;

    if (roundedRestBeats > 0) {
      buffer.push({note: 'rest', beats: roundedRestBeats});
    }

    now += roundedRestSeconds;

    // compute duration of note
    const noteBeats = note.duration * bpm / 60 / timeSignature.denominator
    const roundedNoteBeats = Math.round(noteBeats * baseSubdivision) / baseSubdivision;
    const roundedNoteSeconds = roundedNoteBeats * 60 * timeSignature.denominator / bpm;

    if (roundedNoteBeats > 0) {
      buffer.push({note: f0ToNote(note.f0), beats: roundedNoteBeats});
    }

    now += roundedNoteSeconds;
  }

  const abcNotes: string[] = [];
  for (const {note, beats} of buffer) {
    let base = 'z'; // for rest
    if (note !== 'rest') {
      base = spn2abc(note);
    }

    base += beats * baseSubdivision;
    abcNotes.push(base);
  }
  return `X:1
M: ${timeSignature.numerator}/${timeSignature.denominator}
L: 1/${baseSubdivision}
K: Cmaj
K: clef=bass
|${abcNotes.join('')}|
`;
};

function spn2abc(spn: string): string {
  const pc = Tonal.Note.pc(spn)!;
  const mod = Tonal.Note.oct(spn)! - 4;
  const modString = mod > 0 ? "'".repeat(mod) : ','.repeat(-mod);

  let accidentalString = '';
  if (pc.endsWith('b')) {
    accidentalString = '_';
  } else if (pc.endsWith('#')) {
    accidentalString = '^';
  }
  
  return accidentalString + pc.charAt(0) + modString;
}