import Tone from "tone";

const niceSynth = new Tone.Synth().toMaster();
const clickSynth = new Tone.Synth({
  envelope: {
    attack: 0.02,
    decay: 0.2,
    sustain: 0.1,
    release: 0.9,
  },
}).toMaster();

export function context(): AudioContext {
  // @ts-ignore
  return Tone.context;
}

export const otherContext = new AudioContext();

export function start() {
  // @ts-ignore
  Tone.start();
}

export interface RealTimeNote {
  data: any;
  start: number;
  duration: number;
  note: string | number;
}

export type PlayCallback = (rtn: RealTimeNote) => void;

export function cancel() {
  // @ts-ignore
  Tone.Transport.cancel();
  Tone.Transport.pause(0);
}

export function play(notes: RealTimeNote[], startCb?: PlayCallback) {
  if (notes.length === 0) {
    return Promise.resolve();
  }

  return new Promise(resolve => {
    Tone.Transport.scheduleOnce(time => {
      let lastRtn = notes[0];
      for (let i = 0; i < notes.length; ++i) {
        const rtn = notes[i];
        const { start, duration, note } = rtn;
        niceSynth.triggerAttackRelease(note, duration, time + start);
        if (startCb) {
          Tone.Draw.schedule(() => {
            startCb(rtn);
          }, time + start);
        }

        if (lastRtn.start < rtn.start) {
          lastRtn = rtn;
        }
      }

      Tone.Draw.schedule(() => {
        Tone.Transport.stop();
        resolve();
      }, time + lastRtn.start + lastRtn.duration);
    }, 0);

    Tone.Transport.start();
  });
}

export function setBpm(bpm: number) {
  Tone.Transport.bpm.value = bpm;
}

export function click(times: number, cb?: (n: number) => void) {
  const trigger = (time: number) =>
    clickSynth.triggerAttackRelease("C4", "16n", time);
  const interval = Tone.Time("4n");

  return new Promise(resolve => {
    Tone.Transport.scheduleOnce(time => {
      for (let i = 0; i < times; ++i) {
        trigger(time + i * interval);
        if (cb) {
          Tone.Draw.schedule(() => {
            cb(times - i);
          }, time + i * interval);
        }
      }

      Tone.Draw.schedule(() => {
        Tone.Transport.stop();
        resolve();
      }, time + times * interval);
    }, 0);
    Tone.Transport.start();
  });
}
