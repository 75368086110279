import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter as Router, Route } from "react-router-dom";

import 'md-gum-polyfill';
//import 'audio-context-polyfill';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Home from './pages/home';
import Adhoc from './pages/adhoc';

///

toast.configure();

function updateFakeVH() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}
updateFakeVH();
window.addEventListener('resize', updateFakeVH);

///

ReactDOM.render(<Router>
  <Route exact path="/" component={Home} />
  <Route exact path="/adhoc" component={Adhoc} />
</Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();