import { writeMidi } from 'midi-file';
import { saveAs } from 'file-saver';
import './midi-file.d.ts';

import React from 'react';
import classNames from 'classnames';

import * as Audio from '../../utilities/audio';
import * as Music from '../../utilities/music';
import * as WaveFormAnnotator from '../../components/WaveFormAnnotator';

import {
  RecordingStatus,
  PitchTranscriberUnit,
} from './PitchTranscriberUnit';

export interface TranscriberOptions {
  bpm: number;
  quantize: number | null;
}

export interface Props {
  onError(err: Error): void;
}

export interface State {
  state: 'rest' | 'recording' | 'post-processing' | 'editing';
  wavData: Audio.AudioData | null;
  originalNotes: Music.Note[];
  notes: Music.Note[];
}

export default class Workspace extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      state: 'rest',
      wavData: null,
      notes: [],
      originalNotes: [],
    };
  }

  onStatus = (status: RecordingStatus) => {
    switch (status.type) {
      case 'recording-started': {
        this.setState({
          state: 'recording',
          wavData: null,
        });
        break;
      }
      case 'recording-stopped': {
        this.setState({state: 'post-processing'});
        break;
      }
      case 'recording-transcribed': {
        const wavData = status.wavData;
        const notes = JSON.parse(JSON.stringify(status.notes));
        for (const note of notes) {
          note.f0 = Music.quantizePitch(note.f0);
        }

        this.setState({
          state: 'editing',
          wavData,
          notes: notes,
          originalNotes: notes,
        });
        break;
      }
    }
  };

  render() {
    const { state, wavData } = this.state;
    if (state !== 'editing') {
      return <React.Fragment>
        <div className="row">
          <div className="col">
            <h3 className="text-center"><small className="text-muted">press the big button to record</small></h3>
          </div>
        </div>
        <PitchTranscriberUnit onStatus={this.onStatus} />
        <div className="row" style={{flex: '0 1 auto'}}>
          <div className="col d-flex align-items-center justify-content-center">
            {this.showBottom()}
          </div>
        </div>
      </React.Fragment>;
    }

    return <React.Fragment>
      <div className="row">
        <div className="col">
          <h3 className="text-center"><small className="text-muted">edit any notes if they're wrong</small></h3>
        </div>
      </div>
      <WaveFormAnnotator.Component
        rawData={wavData!}
        notes={this.state.notes}
        onEditorUpdate={(notes) => {
          this.setState({ notes: notes.map(v => v.note) });
        }} />
      <div className="row">
        <div className="col">
          tips:
          <ul>
            <li>you can drag the regions</li>
            <li>drag on the blank space between regions to create a new one</li>
            <li>change note semitones using +/-</li>
            <li>double click a region to play it</li>
          </ul>
        </div>
      </div>
      <div className="row" style={{flex: '0 1 auto'}}>
        <div className="col d-flex align-items-center justify-content-center">
          <div>
            <button onClick={() => {
              const midi = Music.notesToMidi(this.state.notes);
              const midiBuffer = writeMidi(midi);
              const midiArray = new Uint8Array(midiBuffer);
              const midiBlob = new Blob([midiArray], {type: 'audio/x-midi'});
              saveAs(midiBlob, 'out.midi');
            }} className={classNames('btn', 'btn-success')}>
              Get Midi
            </button>
          </div>
          &nbsp;&nbsp;&nbsp;
          <div>
            <button onClick={() => this.setState({state: 'rest'})}
              className='btn btn-primary'>
              Record Another
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>;
  }

  showBottom() {
    const { state } = this.state;
    if (state === 'recording') {
      return 'Recording...';
    }
    if (state === 'post-processing') {
      return 'Please wait...';
    }
    return "Once you've recorded something, your transcribed midi will show up here.";
  }
}