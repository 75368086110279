import React from "react";
import * as Tonal from "tonal";

import {
  faPlay,
  faPause,
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

import WaveSurfer from "wavesurfer.js";
//@ts-ignore
import RegionsPlugin from "wavesurfer.js/src/plugin/regions.js";

import * as Audio from "../utilities/audio";
import * as Music from "../utilities/music";
import * as SoundEffects from "../utilities/sound-effects";

import * as AbcRenderer from "./AbcRenderer";

import "./WaveFormAnnotator.scss";

SoundEffects.start();

export interface Props {
  rawData: Audio.AudioData | string;
  notes: Music.Note[];
  onEditorUpdate?: (rs: TaggedNote[]) => void;
}

export interface State {
  regions: TaggedNote[];
  isRobotPlaying: boolean;
}

export interface TaggedNote {
  note: Music.Note;
  highlight: boolean;
  id: string;
}

export class Component extends React.Component<Props, State> {
  containerRef = React.createRef<HTMLDivElement>();
  wsw: WaveSurferWrapper | null = null;

  constructor(props: Props) {
    super(props);
    this.state = { regions: [], isRobotPlaying: false };
  }

  componentDidMount() {
    const interval = setInterval(() => {
      if (!this.wsw) {
        this.wsw = this.initWSW();
      } else {
        clearInterval(interval);
      }
    });
  }

  componentWillUnmount() {
    if (this.wsw) {
      this.wsw.destroy();
      delete this.wsw;
    }
  }

  initWSW() {
    if (this.wsw) {
      return this.wsw;
    }

    if (this.containerRef.current && this.props.rawData) {
      return new WaveSurferWrapper(
        this.containerRef.current,
        this,
        this.props.rawData,
        this.props.notes,
      );
    }

    return null;
  }

  render() {
    return (
      <React.Fragment>
        <div className="row" style={{ flex: "0 1 auto" }}>
          <div className="col d-flex justify-content-center">
            <div className="wavesurfer-container" ref={this.containerRef}></div>
          </div>
        </div>
        <AbcRenderer.Component
          abc={Music.notes2abc(this.state.regions.map(v => v.note))}
        />
        <div className="row" style={{ flex: "0 1 auto" }}>
          {this.renderContextMenu()}
        </div>
      </React.Fragment>
    );
  }

  renderContextMenu() {
    const { regions } = this.state;
    let note: TaggedNote | null = null;
    for (const id in regions) {
      const region = regions[id];
      if (region.highlight) {
        note = region;
      }
    }

    return (
      <React.Fragment>
        <div className="col-12 col-sm-12 col-lg mt-2 mb-2 d-flex justify-content-center align-items-center">
          {note && Music.f0ToNote(note.note.f0)}
          {!note && "Click on a region to begin!"}
        </div>
        <div className="col-12 col-sm mt-2 mb-2 d-flex justify-content-center align-items-center">
          <button
            className="btn btn-primary"
            onClick={() => this.wsw!.playPause()}
          >
            <Icon icon={faPlay} /> / <Icon icon={faPause} />
          </button>
          &nbsp;
          <button
            className="btn btn-primary"
            disabled={this.state.isRobotPlaying}
            onClick={() => this.robotPlay()}
          >
            Robot &nbsp; <Icon icon={faPlay} />
          </button>
        </div>
        <div className="col-12 col-sm mt-2 mb-2 d-flex justify-content-center align-items-center">
          <button
            className="btn btn-danger"
            disabled={!note}
            onClick={() => this.wsw!.regionAddSemitone(note!)}
          >
            <Icon icon={faPlus} />
          </button>
          &nbsp;
          <button
            className="btn btn-danger"
            disabled={!note}
            onClick={() => this.wsw!.regionSubtractSemitone(note!)}
          >
            <Icon icon={faMinus} />
          </button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button
            className="btn btn-danger"
            disabled={!note}
            onClick={() => this.wsw!.regionRemove(note!)}
          >
            Remove
          </button>
        </div>
      </React.Fragment>
    );
  }

  robotPlay() {
    if (!this.state.isRobotPlaying) {
      const playInput: SoundEffects.RealTimeNote[] = this.state.regions.map(
        r => ({
          start: r.note.start,
          duration: r.note.duration,
          note: r.note.f0,
          data: { id: r.id },
        }),
      );

      playInput.sort((a, b) => a.start - b.start);
      console.log(playInput);

      this.setState({ isRobotPlaying: true }, async () => {
        await SoundEffects.play(playInput, rtn => {
          if (this.wsw) {
            this.wsw.select(rtn.data.id);
          }
        });
        this.setState({ isRobotPlaying: false });
      });
    }
  }
}

const COLOR = "hsla(200, 50%, 70%, 0.4)";

class WaveSurferWrapper {
  ws: WaveSurfer;

  constructor(
    readonly node: HTMLElement,
    readonly component: React.Component<Props, State>,
    rawData: Audio.AudioData | string,
    notes: Music.Note[],
  ) {
    this.ws = WaveSurfer.create({
      container: node,
      height: 100,
      pixelRatio: 1,
      barWidth: 1,
      waveColor: "violet",
      progressColor: "purple",
      scrollParent: true,
      normalize: true,
      audioContext: SoundEffects.otherContext,
      fillParent: true,
      plugins: [RegionsPlugin.create({})],
    });

    this.ws.on("ready", () => {
      for (const { start, duration, f0, power } of notes) {
        this.ws.addRegion({
          start: start,
          end: start + duration,
          drag: true,
          resize: true,
          attributes: {
            highlight: false,
            label: Music.f0ToNote(f0),
          },
          color: COLOR,
          data: { f0, power },
        });
      }
      this._updateComponentState();
      this.ws.on("region-created", region => {
        if (!(region.data && region.data.f0)) {
          region.update({
            data: { f0: Tonal.freq("C4")!, power: 0.5 },
            attributes: { highlight: false, label: "C4" },
          });
          this.select(region.id);
        }
        this._updateComponentState();
      });
    });
    this.ws.enableDragSelection({ color: COLOR });
    for (const name of ["region-update-end", "region-removed"]) {
      this.ws.on(name, () => this._updateComponentState());
    }
    this.ws.on("region-click", (region, event) => {
      this.select(region.id);
      event.stopPropagation();
    });
    this.ws.on("region-dblclick", r => {
      SoundEffects.play([
        {
          start: 0,
          duration: 0.5,
          note: r.data.f0,
          data: { id: r.id },
        },
      ]);
    });
    this.ws.on("region-in", region => {
      this.select(region.id);
    });

    if (typeof rawData === "string") {
      this.ws.load(rawData);
    } else {
      const audioBuffer = Audio.createAudioBuffer(rawData);
      this.ws.loadDecodedBuffer(audioBuffer);
    }
  }

  select(id: string) {
    for (const rid in this.ws.regions.list) {
      this.ws.regions.list[rid].update({
        attributes: {
          highlight: rid === id,
        },
      });
    }
    this._updateComponentState();
  }

  regionRemove(note: TaggedNote) {
    this.ws.regions.list[note.id].remove();
  }

  regionAddSemitone(note: TaggedNote) {
    this._regionChangeNote(note, +1);
  }

  regionSubtractSemitone(note: TaggedNote) {
    this._regionChangeNote(note, -1);
  }

  _updateComponentState() {
    const regions = mkState(this.ws.regions.list);
    if (this.component.props.onEditorUpdate) {
      this.component.props.onEditorUpdate(regions);
    }
    this.component.setState({ regions });
  }

  _regionChangeNote(note: TaggedNote, semitones: number) {
    const noteMidi = Tonal.Note.freqToMidi(note.note.f0)!;
    const f0 = Tonal.Note.midiToFreq(noteMidi + semitones)!;
    const noteName = Music.f0ToNote(f0);
    const region = this.ws.regions.list[note.id];
    region.update({
      attributes: { label: noteName, highlight: region.attributes.highlight },
      data: { f0, power: region.data.power },
    });
    this._updateComponentState();
  }

  destroy() {
    this.ws.unAll();
    this.ws.destroy();
  }

  playPause() {
    this.ws.playPause();
  }
}

function mkState(regionsList: { [a: string]: any }): TaggedNote[] {
  const rv: TaggedNote[] = [];
  for (const key in regionsList) {
    const region = regionsList[key];
    rv.push({
      note: {
        start: region.start as number,
        duration: region.end - region.start,
        f0: region.data.f0,
        power: region.data.power,
      },
      id: region.id,
      highlight: region.attributes && region.attributes.highlight,
    });
  }
  return rv;
}
