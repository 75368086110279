import React from 'react';

import * as d3 from 'd3';
import {scaleLinear} from 'd3-scale';
import {range} from 'd3-array';
import {select} from 'd3-selection';

const n = 200, domain = [0, n - 1];

function computeDimensions(root) {
  const baseWidth = root._groups[0][0].clientWidth;
  const baseHeight = 100;
  const margin = { top: 5, right: 0, bottom: -1, left: 0 },
    width = baseWidth - margin.right,
    height = baseHeight - margin.top - margin.bottom;
  const svgWidth = width + margin.left + margin.right;
  const svgHeight = height + margin.top + margin.bottom;

  return { margin, width, height, svgWidth, svgHeight };
}

function recreate(root, data) {
  const {margin, width, height, svgWidth, svgHeight} = computeDimensions(root);
  const x = d3.scaleLinear()
    .domain(domain)
    .range([0, width]);

  const y = scaleLinear()
    .domain([0, 1])
    .range([1, height]);

  const area = d3.area()
    .curve(d3.curveStep)
    .x((_, i) => x(i))
    .y0((d, _) => (height + y(d)) / 2)
    .y1((d, _) => (height - y(d)) / 2)
    ;

  const svg = root
    .append("p")
    .append("svg")
      .attr("id", "svgContainer")
      .attr("width", svgWidth)
      .attr("height", svgHeight)
      .style("margin-left", -margin.left + "px")
    .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  svg
    .append("defs")
    .append("clipPath")
      .attr("id", "clip")
    .append("rect")
      .attr("id", "clipRect")
      .attr("width", width)
      .attr("height", height);
  
  const path = svg
    .append("g")
      .attr("clip-path", "url(#clip)")
    .append("path")
      .datum(data)
      .attr("id", "svgPath")
      .attr("class", "area")
      .attr("d", area);
  return {path, area, x, y};
}


function createD3(ref, newDataPoint) {
  const root = select(ref);
  if (root.empty()) {
    return;
  }

  let path = root.select("#svgPath");
  if (path.empty()) {
    const data = range(n).map(() => 0);

    let {path, area, x} = recreate(root, data);
    let isRunning = false;
    
    function start() {
      if (!isRunning) {
        isRunning = true;
        data.fill(0);
        path.transition()
          .duration(100)
          .ease(d3.easeLinear)
          .on("start", tick)
          ;
      }
    }

    function stop() {
      if (isRunning) {
        isRunning = false;
      }
    }

    function destroy() {
      stop();
      window.removeEventListener('resize', onWindowResize);
    }

    function tick() {
      data.push(newDataPoint());

      d3.select(this)
        .attr("d", area)
        .attr("transform", null);

      if (isRunning) {
        d3.active(this)
          .attr("transform", `translate(${x(-1)},0)`)
          .transition()
          .on("start", tick)
          ;
      }
      data.shift();
    }

    function onWindowResize() {
      root.selectAll('*').remove();
      ({path, area, x} = recreate(root, data));
    }

    window.addEventListener('resize', onWindowResize)

    return {start, stop, destroy};
  }
}

class Viz extends React.Component {
  vizRef = React.createRef();

  componentDidMount() {
    // Spin until the div in `render` is ready and our d3 program is attached
    const interval = setInterval(() => {
      if (!this.d3) {
        this.d3 = this.props.createD3(this.vizRef.current, this.props.newDataPoint);
      } else {
        clearInterval(interval);
      }
    });
  }

  componentWillUnmount() {
    if (this.d3) {
      this.d3.destroy();
      delete this.d3;
    }
  }

  componentDidUpdate() {
    if (this.d3) {
      if (this.props.isDrawing) {
        this.d3.start();
      } else {
        this.d3.stop();
      }
    }
  }

  render() {
    return <div ref={this.vizRef} />;
  }
}

export { Viz, createD3 };