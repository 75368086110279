import React from 'react';
import { toast } from 'react-toastify';
import { faDotCircle, faPauseCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import { pad } from '../../utilities/javascript';
import { Note } from '../../utilities/music';
import * as Audio from '../../utilities/audio';
import * as SoundEffects from '../../utilities/sound-effects';
import { HumRecorder, StopEvent, RecorderListener, RecorderEvent } from '../../utilities/hum-recorder';
import { Viz, createD3 } from './waveform-viz';

const bpm = 120;
const beatsBeforeRecording = 4;
const timeoutSeconds = 10;

export interface RecordingStarted { type: 'recording-started' };
export interface RecordingStopped { type: 'recording-stopped' };
export interface RecordingTranscribed {
  type: 'recording-transcribed';
  nonEmpty: boolean;
  notes: Note[];
  wavData: Audio.AudioData;
};

export type RecordingStatus = RecordingStarted | RecordingStopped | RecordingTranscribed;

export interface PTUProps {
  onStatus(status: RecordingStatus): void;
};

export interface PTUState {
  recordingState: 'rest' | 'pre-recording' | 'recording' | 'post-processing';
  lastAmplitude: number;
  secondsRemainingBeforeAutoStop: number;
  currentCountDownValue: number;
};

export class PitchTranscriberUnit extends React.Component<PTUProps, PTUState> implements RecorderListener {
  autoStopInterval: NodeJS.Timeout | null = null;
  startTime: Date = new Date();
  humRecorder: HumRecorder = HumRecorder.create(this);

  constructor(props: PTUProps) {
    super(props);
    this.state = {
      recordingState: 'rest',
      lastAmplitude: 0,
      secondsRemainingBeforeAutoStop: timeoutSeconds,
      currentCountDownValue: 0,
    };
  }

  componentWillUnmount() {
    if (this.humRecorder) {
      this.humRecorder.destroy();
      delete this.humRecorder;
    }
  }

  render() {
    const {
      recordingState,
      secondsRemainingBeforeAutoStop,
      currentCountDownValue
    } = this.state;
    const userWantedToStartRecording = recordingState === 'recording' || recordingState === 'pre-recording';
    let secondDisplay = `0:${pad(timeoutSeconds, 2)}`;
    if (recordingState === 'recording') {
      secondDisplay = `0:${pad(secondsRemainingBeforeAutoStop, 2)}`;
    } else if (recordingState === 'pre-recording') {
      secondDisplay = `${currentCountDownValue}...`;
    }

    return <React.Fragment>
      <div className="row" style={{flex: '0 1 auto'}}>
        <div className="col d-flex justify-content-center" style={{fontSize: '100px'}}>
          {userWantedToStartRecording
            ? <span onClick={() => this.stopRecording()}><Icon icon={faPauseCircle}/></span>
            : <span onClick={() => this.recordingButtonClicked()}><Icon icon={faDotCircle}/></span>}
        </div>
        <div className="col d-flex align-items-center justify-content-center">
          Some options here
        </div>
      </div>
      <div className="row" style={{flex: '0 1 auto'}}>
        <div className="col d-flex justify-content-center">
          {secondDisplay}
        </div>
      </div>
      <div className="row" style={{flex: '0 1 auto'}}>
        <div className="col text-center">
          <Viz
            isDrawing={this.state.recordingState === 'recording'}
            createD3={createD3}
            newDataPoint={() => this.latestRMS()}/>
        </div>
      </div>
    </React.Fragment>;
  }

  latestRMS() {
    if (this.state.recordingState !== 'recording') {
      return 0.;
    }
    return this.state.lastAmplitude;
  }

  async stopRecording() {
    switch (this.state.recordingState) {
      case 'pre-recording': {
        // Ideally we'd also cancel the countdown here but tbh implementing that would introduce
        // a lot of complexity into our state management, so I'm going to shelve that idea
        this.setState({recordingState: 'rest'});
        break;
      }
      case 'recording': {
        if (this.autoStopInterval) {
          clearInterval(this.autoStopInterval);
          this.autoStopInterval = null;
        }
        this.setState({recordingState: 'post-processing'}, async () => {
          this.props.onStatus({type: 'recording-stopped'});
          await this.humRecorder.stop(); // no point in awaiting here but we'll do it anyway
        });
        break;
      }
      default: {
        console.log(`PTU.stopRecording() called when in ${this.state.recordingState} state!`);
        break;
      }
    }
  }

  async recordingButtonClicked() {
    if (this.state.recordingState === 'rest') {
      try {
        await this.humRecorder.initialize();
        this.setState({
          recordingState: 'pre-recording',
          currentCountDownValue: beatsBeforeRecording,
        }, async () => {
          SoundEffects.start();
          await this.countOff();
          await this.startRecording();
        });
      } catch (err) {
        console.log(err);
        toast(err.message);
      }
    } else {
      console.log(`PTU.recordingButtonClicked() called when in ${this.state.recordingState} state!`);
    }
  }

  async startRecording() {
    switch (this.state.recordingState) {
      case 'rest': {
        // Do nothing, the start was cancelled
        break;
      }
      case 'pre-recording': {
        await this.humRecorder.start();
        this.startTime = new Date();
        this.tick();
        this.autoStopInterval = setInterval(() => this.tick(), 250);
        break;
      }
      default: {
        console.log(`PTU.startRecording() called when in ${this.state.recordingState} state!`);
      }
    }
  }

  async countOff() {
    SoundEffects.setBpm(bpm);
    await SoundEffects.click(beatsBeforeRecording, (currentCountDownValue) => {
      this.setState({currentCountDownValue});
    });
  }

  tick() {
    const secondsLeft = timeoutSeconds + (this.startTime.getTime() - new Date().getTime()) / 1000;
    const roundedSecondsLeft = Math.round(secondsLeft);
    if (secondsLeft <= 0.) {
      this.stopRecording();
    }
    this.setState({secondsRemainingBeforeAutoStop: roundedSecondsLeft});
  }

  onRecorderEvent(event: RecorderEvent) {
    this.setState({
      lastAmplitude: event.amplitude,
    });
  }

  onStop(event: StopEvent) {
    this.setState({recordingState: 'rest'}, () => {
      this.props.onStatus({type: 'recording-transcribed', ...event});
    });
  }

  onStart() {
    this.setState({
      recordingState: 'recording',
      lastAmplitude: 0.,
    }, () => {
      this.props.onStatus({type: 'recording-started'});
    });
  }
}