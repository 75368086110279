import React from 'react';

//@ts-ignore
import abcjs from 'abcjs';

export interface AbcRendererProps {
  abc: string;
};

export class Component extends React.Component<AbcRendererProps> {
  abcRef = React.createRef<HTMLDivElement>();
  interval: number = 0;

  componentDidMount() {
    let lastAbc = '';
    this.interval = window.setInterval(() => {
      if (this.props.abc !== lastAbc && this.abcRef.current) {
        abcjs.renderAbc(this.abcRef.current, this.props.abc, {
          responsive: 'resize',
        });
        lastAbc = this.props.abc;
      }
    }, 100);
  }

  componentWillUnmount() {
    if (this.interval) {
      window.clearInterval(this.interval);
      this.interval = 0;
    }
  }

  render() {
    return <div className='row'>
      <div className='col'>
        <div ref={this.abcRef} />
      </div>
    </div>;
  }
}