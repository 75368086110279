import Tone from "tone";

import { Recorder, GetBufferCallback } from "../vendor/recorder";
import NTIface from "../noteTranscriptionInterface";

import { Note } from "./music";
import * as Audio from "./audio";
import * as SoundEffects from "./sound-effects";

const pcmBufLength = 2048;

export interface RecorderEvent {
  readonly timestamp: number;
  readonly amplitude: number;
}

export interface StopEvent {
  readonly nonEmpty: boolean;
  readonly wavData: Audio.AudioData;
  readonly notes: Note[];
}

export interface RecorderListener {
  onStart(): void;
  onRecorderEvent(event: RecorderEvent): void;
  onStop(event: StopEvent): void;
}

export class HumRecorder {
  static create(listener: RecorderListener) {
    const context = SoundEffects.context();
    return new HumRecorder(listener, context);
  }

  scriptProcessor: ScriptProcessorNode;

  _audioSource: MediaStreamAudioSourceNode | null = null;
  _recorder: Recorder | null = null;
  _stream: MediaStream | null = null;

  _audioProcess = (event: AudioProcessingEvent) => {
    const buf = event.inputBuffer.getChannelData(0);
    const amplitude = computeRMSAmplitude(buf);
    const timestamp = event.playbackTime;

    this.listener.onRecorderEvent({ timestamp, amplitude });
  };

  async initialize() {
    if (!this._recorder) {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });
      this._audioSource = this.context.createMediaStreamSource(stream);
      this._audioSource.connect(this.scriptProcessor);

      this._stream = stream;
      this._recorder = new Recorder(this._audioSource, {
        bufferLen: pcmBufLength,
      });
    }
  }

  async recorder() {
    await this.initialize();
    return this._recorder!;
  }

  constructor(
    readonly listener: RecorderListener,
    readonly context: AudioContext,
  ) {
    this.scriptProcessor = this.context.createScriptProcessor(
      pcmBufLength,
      1,
      1,
    );
    Tone.connect(this.scriptProcessor, this.context.destination);
    // this.scriptProcessor.connect(this.context.destination);
    this.scriptProcessor.addEventListener("audioprocess", this._audioProcess);
  }

  async start() {
    await this.context.resume();
    const recorder = await this.recorder();
    recorder.record();
    this.listener.onStart();
  }

  async stop() {
    const recorder = await this.recorder();

    recorder.stop();

    // I'd like to call suspend here but it turns out suspending the audiocontext too many times
    // causes it to not work???
    // await this.context.suspend();

    const bufferPromise = new Promise((resolve: GetBufferCallback) =>
      recorder.getBuffer(resolve),
    );
    const buffer = await bufferPromise;

    // If we accidentally recorded 0 samples, we do not want to transcribe them
    const result = {
      nonEmpty: false,
      notes: [],
    };
    if (buffer.length > 0 && buffer[0].length > 0) {
      const ntiResult = await NTIface.transcribe(buffer, {
        sampleRate: this.context.sampleRate,
      });
      console.log(ntiResult);
      Object.assign(result, ntiResult);
      result.nonEmpty = true;
    }

    this.listener.onStop({
      wavData: {
        sampleRate: this.context.sampleRate,
        channelData: buffer,
      },
      ...result,
    });

    recorder.clear();
  }

  destroy() {
    if (this._stream) {
      const tracks = this._stream.getTracks();
      for (const track of tracks) {
        track.stop();
      }
      this._audioSource!.disconnect();
      this._recorder!.node.disconnect();
      this.scriptProcessor.removeEventListener(
        "audioprocess",
        this._audioProcess,
      );
      this.scriptProcessor.disconnect();

      delete this._stream;
      delete this._recorder;
      delete this._audioSource;
    }
  }
}

function computeRMSAmplitude(buf: Float32Array) {
  let ms = 0;
  for (let i = 0; i < buf.length; ++i) {
    const numEncountered = i + 1;
    const value = buf[i] * buf[i];
    ms = (ms * (numEncountered - 1)) / numEncountered + value / numEncountered;
  }
  return Math.sqrt(ms);
}
