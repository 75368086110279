import React from 'react';

import NTIface from '../../noteTranscriptionInterface';
import Workspace from './Workspace';
import Footer from '../../components/Footer';

import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import '../../base.scss';

export default class Home extends React.Component<{}, {status: string}> {
  constructor(props: {}) {
    super(props);
    this.state = {status: ''};
  }

  async componentDidMount() {
    try {
      try {
        await navigator.mediaDevices.getUserMedia({});
      } catch (e) {
        if (!(e instanceof TypeError)) {
          throw e;
        } 
      }
      await NTIface.ready();
      this.setState({status: 'ready'});
    } catch (e) {
      this.setState({status: e.message});
    }
  }

  render() {
    if (!this.state.status) {
      return <div className="container" style={{flex: '1 1 auto'}}>
        <div className="row">
          <div className="col">
            <h3 className="mt-3">Please wait...</h3>
          </div>
        </div>
      </div>
    }

    if (this.state.status !== 'ready') {
      return <div className="container" style={{flex: '1 1 auto'}}>
        <div className="row">
          <div className="col">
            <h3 className="mt-3">Could not start! :(</h3>
            <pre>{this.state.status}</pre>
          </div>
        </div>
      </div>
    }

    return <React.Fragment>
      <div className="container" style={{flex: '0 1 auto'}}>
        <div className="row">
          <div className="col">
            <h1 className="text-center mt-3">Hummingbird</h1>
          </div>
        </div>
      </div>
      <article className="container d-flex flex-column" style={{flex: '1 1 auto'}}>
        <Workspace onError={(v) => {}}/>
      </article>
      <div className="cta pt-4 pb-4 mt-4">
      <div className="container">
        <form
          action="https://yuzeh.us20.list-manage.com/subscribe/post?u=9d635431555fab087eadca968&amp;id=8e8d1e5a3c"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form" className="validate"
          target="_self">
          <div className="row">
            <div className="col">
              <h4>I'm building something interesting!</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-8 col-lg-4">
              <label htmlFor="mce-EMAIL">Sign up for updates:</label>
              <input type="email" name="EMAIL" className="form-control" id="mce-EMAIL" placeholder="Email" />
            </div>
            <div className="col-4 col-lg-2 d-flex align-items-end">
              <button type="submit" className="btn btn-primary" name="subscribe" id="mc-embedded-subscribe">
                <Icon icon={faEnvelope} />
              </button>
            </div>
            <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
              <input type="text" name="b_9d635431555fab087eadca968_8e8d1e5a3c" tabIndex={-1} />
            </div>
          </div>
        </form>
      </div>
      </div>
      <Footer />
    </React.Fragment>;
  }
};
